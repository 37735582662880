@import '/src/styles/utilities.scss';

.comic__container {
  container-type: inline-size;
}

.comic {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;

  @include media-breakpoint-up(lg) {
    overflow-x: inherit;
  }

  &_square {
    justify-content: center;

    // Gray background only shows when container is greater than 750px
    @container (min-width: 750px) {
      padding: $space-20;
      background-color: var(--color-gray-100);
      border-radius: $border-radius-25;
    }
  }
}

.comic__lightboxTrigger {
  @include undo-default-button-styles();

  position: relative;
  display: inline-block;
  width: 100%;
  cursor: nesw-resize;
}

.comic__image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0 auto;

  &_square {
    @include media-breakpoint-up(lg) {
      width: unset;
      min-width: 400px;
    }
  }

  &_strip {
    min-width: 900px;
    @include media-breakpoint-up(lg) {
      min-width: unset;
    }
  }
}
